/* This file will contain project specific styling */
/* Any styling that can be used cross projects should be written in palmtto-ui-components package */

*, *:before, *:after {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}


::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c6c5c5;
}
::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
}


.palmetto-info {
    margin-top: 14px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    text-align: center;
}

/* Grid styling */

.ReactTable {
    padding: 0;
}
.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    padding: 0 !important;
}

.ReactTable .rt-thead.-header {
    box-shadow: unset;
}

/* Row highlighting */
.ReactTable .rt-table .rt-tbody .rt-tr-group:hover {
	 background: rgba(0, 0, 0, 0.05);
}

.no-overflow-y {
    overflow-y: unset;
}

.dialog-content-padding-adjust {
    padding: 8px 16px;
}

input[type="file"] {
    display: none;
    visibility: hidden;
    width: 0;
    height: 0;
}

.form-margin {
    margin: 0 6px;
}

.body-cell {
    height: 43px;
    padding-left: 18px !important;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    /* color: var(--primary-text-color)  !important; */
    cursor: pointer !important;
    
    border-bottom: 1px solid #E0E0E0;
}

.body-header {
    padding-left: 18px;
    font-size: 16px !important;
    background: #f5f5f5 !important;
    font-weight: 500 !important;
    min-height: 42px !important;
    line-height: 0;
    box-sizing: border-box;
    box-shadow: 0 -1px 0 #e0e0e0 inset !important;
}

.placeholder {
    color: rgba(0, 0, 0, 0.40);
}

/* Map styling */

.leaflet-container {
    width: 100%;
    height: calc(100vh - 56px);
}

.leaflet-popup-content {
    margin: unset;
    line-height: unset;
    height: 360px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
    box-shadow: unset;
    width: 280px;
}

.react-editor-mitigation {
    height: 50%;
    /* border: 1px solid; */
}

.custom-popup {
    width: 100%;
    height: 300px !important;
}

.leaflet-popup-close-button {
    display: none;
}

.leaflet-popup {
    position: absolute;
    text-align: right;
}

.leaflet-layer {
    filter: grayscale(80%) !important;
}

/* Global tag styling */

a {
    text-decoration: none !important;
    color: black !important;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.cursor-pointer {
    cursor: pointer;
}

/* Popup style */

.esri-popup__header {
    display: none !important;
}

.esri-popup__main-container {
    max-height: 300px !important;
}

.esri-popup__content {
    margin: unset !important;
    height: 300px;
}

.esri-popup__content > * {
    height: 300px;
}


.esri-popup__footer {
    display: none !important;
}